<template>
    <div>
        <TableList
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['status']"
            :is_created_get_list="false"
            :form_data_seo="form_data_seo"
            ref="list"
            :rowKey="'id'"
            @list_after="list_after"
            :rowSelection="rowSelection"
        >
            <template slot="left_btn">
                <a-button
                    type="primary"
                    style="margin-left: 15px;"
                    @click="start"
                    :disabled="!hasSelected"
                    :loading="loading"
                >批量更改业务员</a-button>
                <a-button
                    :disabled="!hasSelected"
                    style="margin-left: 15px;"
                    @click="handle_invalid"
                >批量设置无效</a-button>
            </template>

            <template slot="status" slot-scope="data">
                <a-tag color="#108ee9">
                    <span
                        v-for="item in status_list"
                        :key="item.key"
                        v-show="item.key == data.text"
                    >{{item.value}}</span>
                </a-tag>
            </template>
        </TableList>
        <SelectUser @submit="submit" :visible.sync="user_visible"></SelectUser>
    </div>
</template>

<script>

import TableList from "@/components/TableList";
import { infoList, changeStatus, setUser, getScreen } from "@/api/bigdata";
import SelectUser from "@/components/SelectUser";

const columns = [

    {
        title: "客户名称",
        dataIndex: "title"
    },
    {
        title: "联系方式",
        dataIndex: "phone"
    },
    {
        title: "所属省份",
        dataIndex: "province"
    },
    {
        title: "所属城市",
        dataIndex: "city"
    },
    {
        title: "所属区县",
        dataIndex: "district"
    },
    {
        title: "详情地址",
        dataIndex: "address",
    },
    {
        title: "所属分类",
        dataIndex: "cate"
    },
    {
        title: "所属区域",
        dataIndex: "area"
    },
    {
        title: "状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
];

let status_list = [
    {
        key: 2,
        value: '已分配'
    },
    {
        key: 1,
        value: '--'
    },
    {
        key: 0,
        value: '无效'
    },
];
export default {
    name: "Index",
    components: {
        TableList,
        SelectUser
    },
    data() {
        return {
            query: {},
            form_data_seo: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: "select",
                        name: "cate",
                        title: "分类",
                        mode: "default",
                        options: {},
                        list: []
                    },
                    {
                        type: "select",
                        name: "area",
                        title: "区域",
                        mode: "default",
                        options: {},
                        list: []
                    },
                    {
                        type: "text",
                        name: "title",
                        title: "客户名称",
                        placeholder: "客户名称",
                        options: {}
                    },
                    {
                        type: "select",
                        name: "status",
                        title: "客户状态",
                        mode: "default",
                        options: {},
                        list: status_list
                    },
                ],

            },
            get_table_list: infoList,
            status_list,
            columns,
            type: '',
            selectedRowKeys: [],
            customer_ids: '',
            user_visible: false,
        };
    },
    computed: {
        hasSelected() {
            return this.selectedRowKeys.length > 0;
        },
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    this.selectedRowKeys = selectedRowKeys;
                    this.customer_ids = selectedRowKeys.join(',')
                },
                hideDefaultSelections: true,
            };
        }
    },
    async created() {
		this.query = this.$route.query;
		
		Promise.all([getScreen({data:{...this.query}})]).then(res=>{
			this.form_data_seo.list.forEach(item => {
                if (item.name == "cate") {
                    item.list = res[0].data.cate_list;
				}
				if (item.name == "area") {
                    item.list = res[0].data.area_list;
				}
            });
		});

        this.$nextTick(res => {
            this.$refs.list.set_data('fixed_seo_data', this.query)
            this.$refs.list.get_list();
        })
    },
    methods: {
        to_details(record, type) {

        },
        list_after({ res }) {

        },
        start() {
            this.user_visible = true
        },
        submit({ data }) {
            let obj = {
                ...this.query,
                ids: this.customer_ids,
            }
            obj.uid = data.id;
            setUser({
                data: obj,
                info: true
            }).then(res => {
                this.user_visible = false;
                this.$refs.list.get_list();
                this.selectedRowKeys = [];
                this.customer_ids = '';
            })

        },

        handle_invalid() {
            changeStatus({
                data: {
                    ...this.query,
                    ids: this.customer_ids
                },
                info: true
            }).then(res => {
                this.$refs.list.get_list();
            })
        }
    }
};
</script>

<style lang="less">
@import url("../../../assets/less/app.less");
</style>